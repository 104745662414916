import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Links } from '@/utils/constants';
import { removeSpaces } from '@/utils/functions';
import * as styled from './PWAPreview.styled';
import { AI } from '../AI';
import { TemplateCharacteristicsDropDown } from '../Templates/components';
import { Button, DropDown, Icon, Image, Name } from '../general';

enum DeviceModes {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
}

interface IPWAPreview {
  data: any;
}

export const PWAPreview: React.FC<IPWAPreview> = ({ data }) => {
  const isBrowser = () => typeof window !== 'undefined';
  const desktopByDefault = isBrowser() && window.innerWidth < 768;
  const {
    title,
    technologies,
    mainIcon,
    categories,
    studioTemplateId,
    includesList,
    previewUrl,
  } = data;
  const ref = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [width, setWidth] = useState<string>();
  const [height, setHeight] = useState<string>();
  const [deviceMode, setDeviceMode] = useState(
    desktopByDefault ? DeviceModes.DESKTOP : DeviceModes.MOBILE,
  );
  const inDevice = deviceMode === DeviceModes.MOBILE;
  const message = useMemo(() => ({ inDevice }), [inDevice]);
  const key = JSON.stringify(message) + previewUrl;

  useEffect(() => {
    const onMessage = (e: any) =>
      e.data === 'READY' &&
      iframeRef.current?.contentWindow?.postMessage(message, previewUrl);
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  }, [key]);

  useEffect(() => {
    const onResize = () =>
      setDeviceMode(
        window.innerWidth < 768 ? DeviceModes.DESKTOP : DeviceModes.MOBILE,
      );
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  const onResizeMouseDown = (
    { currentTarget }: React.MouseEvent<HTMLDivElement>,
    bottom?: boolean,
  ) => {
    document.onmousemove = (e) => {
      document.body.style.userSelect = 'none';
      document.body.style.cursor = bottom ? 'ns-resize' : 'ew-resize';
      currentTarget.style.setProperty('background', '#99a9cc');
      ref.current.style.pointerEvents = 'none';
      const { width, height } = ref.current.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;
      const compression =
        innerHeight <= 670
          ? 0.6
          : innerHeight <= 760
          ? 0.7
          : innerHeight <= 850
          ? 0.8
          : innerHeight <= 940
          ? 0.9
          : 1;
      if (bottom) {
        const newHeight =
          (height -
            82 +
            (e.pageY - (height + (innerHeight - height) / 2)) * 2) /
          compression;
        if (newHeight >= 350 && e.pageY < innerHeight) {
          setHeight(`${newHeight}px`);
        }
      } else {
        const newWidth =
          (width - 22 + (e.pageX - (width + (innerWidth - width) / 2)) * 2) /
          compression;
        if (newWidth >= 350 && e.pageX < innerWidth) {
          setWidth(`${newWidth}px`);
        }
      }
    };
    document.onmouseup = () => {
      document.body.style.removeProperty('user-select');
      document.body.style.removeProperty('cursor');
      currentTarget.style.removeProperty('background');
      ref.current.style.removeProperty('pointer-events');
      document.onmousemove = () => null;
      document.onmouseup = () => null;
    };
  };

  return (
    <>
      <styled.Header>
        <styled.HeaderItem $left>
          <DropDown
            options={[
              {
                text: 'Back to template',
                iconName: Name.SECOND_ORDER_GO_BACK,
                to: `${Links.TEMPLATES}/${removeSpaces(title)}`,
              },
            ]}
            top="54px"
            left="54px"
            width="240px"
            labelElement={
              <styled.Logo>
                <Image src={mainIcon.url} width="40px" height="40px" />
                <styled.ArrowControl>
                  <Icon name={Name.SECOND_ORDER_ARROW} />
                </styled.ArrowControl>
              </styled.Logo>
            }
          />
          <styled.TitleWithCategoriesBox>
            <styled.Title>{title}</styled.Title>
            <styled.CategoriesBox>{categories.join(', ')}</styled.CategoriesBox>
          </styled.TitleWithCategoriesBox>
        </styled.HeaderItem>
        <styled.HeaderItem $center>
          {Object.values(DeviceModes).map((mode) => (
            <styled.Box
              key={mode}
              $active={deviceMode === DeviceModes[mode]}
              onClick={() => setDeviceMode(DeviceModes[mode])}
            >
              <Icon name={Name[`SECOND_ORDER_${mode}`]} />
            </styled.Box>
          ))}
        </styled.HeaderItem>
        <styled.HeaderItem $right>
          <AI
            intent={
              studioTemplateId ? 'start-building' : 'request-early-access'
            }
            templateName={studioTemplateId ? undefined : title}
            templateId={studioTemplateId}
          >
            <Button height={30}>Start with template</Button>
          </AI>
        </styled.HeaderItem>
      </styled.Header>
      <styled.Page>
        <styled.FrameWrapper
          ref={ref}
          $mobileMode={deviceMode === DeviceModes.MOBILE}
          $desktopMode={deviceMode === DeviceModes.DESKTOP}
          $width={width}
          $height={height}
        >
          {deviceMode === DeviceModes.MOBILE ? (
            <styled.Home />
          ) : deviceMode === DeviceModes.TABLET ? (
            <>
              <styled.Resizer onMouseDown={(e) => onResizeMouseDown(e)} />
              <styled.Resizer
                $bottom
                onMouseDown={(e) => onResizeMouseDown(e, true)}
              />
            </>
          ) : null}
          <iframe
            ref={iframeRef}
            key={key}
            title="Preview"
            src={previewUrl}
            width="100%"
            height="100%"
          />
        </styled.FrameWrapper>
      </styled.Page>
      <TemplateCharacteristicsDropDown
        includesList={includesList}
        technologies={technologies}
        previewUrl={previewUrl}
      />
    </>
  );
};
