import styled, { css } from 'styled-components';

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f3;
  border-bottom: 1px solid #dfdfea;
  z-index: 1;
`;

export const ArrowControl = styled.div`
  position: absolute;
  display: flex;
  bottom: 5px;
  right: 5px;
  transition: ${(props) => props.theme.transition.primary};
  transform: translate(0, 0);
  opacity: 0.7;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60px;
  height: 100%;
  border-right: 1px solid #dfdfea;
  cursor: pointer;
  &:hover {
    ${ArrowControl} {
      transform: translate(2px, 2px);
      opacity: 1;
    }
  }
`;

export const HeaderItem = styled.div<{
  $left?: boolean;
  $center?: boolean;
  $right?: boolean;
}>`
  width: calc(100% / 3);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.$left
      ? 'flex-start'
      : props.$center
      ? 'center'
      : props.$right
      ? 'flex-end'
      : ''};
  padding-right: ${(props) => props.$right && '20px'};

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: ${(props) => props.$center && 'none'};
    padding-right: ${(props) => props.$right && '10px'};
    width: calc(100% / 2);
  }
`;

export const TitleWithCategoriesBox = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const CategoriesBox = styled.div`
  color: #7b7b8d;
  font-size: 12px;
  font-weight: ${(props) => props.theme.weights.regular};
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: ${(props) => props.theme.weights.bold};
`;

export const Box = styled.div<{ $active?: boolean }>`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  margin: 0 5px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.$active ? '#9a9a9a26' : '#f2f2f3')};
  cursor: pointer;
  & svg {
    & path,
    & rect {
      transition: 300ms ease-out;
      &[fill] {
        fill: #7f899e;
      }
      &[stroke] {
        stroke: #7f899e;
      }
    }
  }
  &:hover svg {
    & path,
    & rect {
      &[fill] {
        fill: #212133;
      }
      &[stroke] {
        stroke: #212133;
      }
    }
  }
`;

export const Page = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
`;

export const FrameWrapper = styled.div<{
  $mobileMode: boolean;
  $desktopMode: boolean;
  $width?: string;
  $height?: string;
}>`
  position: relative;
  max-width: ${(props) =>
    !props.$desktopMode && !props.$mobileMode && (props.$width || '1180px')};
  max-height: ${(props) =>
    !props.$desktopMode && !props.$mobileMode && (props.$height || '820px')};
  width: ${(props) =>
    props.$desktopMode
      ? '100%'
      : props.$mobileMode
      ? '410px'
      : props.$width || '100%'};
  height: ${(props) =>
    props.$desktopMode
      ? '100%'
      : props.$mobileMode
      ? '864px'
      : props.$height || '100%'};
  border-radius: ${(props) =>
    props.$mobileMode ? '60px' : !props.$desktopMode && '12px'};
  border: ${(props) =>
    props.$mobileMode
      ? '10px solid #2d2d2d'
      : !props.$desktopMode && '0.5px solid #dfdfea'};
  box-shadow: ${(props) =>
    !props.$mobileMode &&
    !props.$desktopMode &&
    '0px 2px 50px 0px rgba(26, 24, 24, 0.15)'};
  overflow: ${(props) => props.$mobileMode && 'hidden'};
  & > iframe,
  & > img {
    border-radius: ${(props) => !props.$mobileMode && 'inherit'};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md + 1}px) {
    ${(props) =>
      !props.$desktopMode &&
      css`
        @media (max-height: 940px) {
          transform: scale(0.9);
        }
        @media (max-height: 850px) {
          transform: scale(0.8);
        }
        @media (max-height: 760px) {
          transform: scale(0.7);
        }
        @media (max-height: 670px) {
          transform: scale(0.6);
        }
      `}
  }
`;

export const Home = styled.div`
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: 134px;
  border-radius: 10px;
  background: #2d2d2d;

  @media (max-width: ${(props) => props.theme.breakpoints.md}px) {
    display: none;
  }
`;

export const Resizer = styled.div<{ $bottom?: boolean }>`
  position: absolute;
  top: ${(props) => (props.$bottom ? 'calc(100% + 10px)' : '50%')};
  left: ${(props) => (props.$bottom ? '50%' : 'calc(100% + 10px)')};
  transform: ${(props) =>
    props.$bottom ? 'translateX(-50%)' : 'translateY(-50%)'};
  width: ${(props) => (props.$bottom ? 40 : 4)}px;
  height: ${(props) => (props.$bottom ? 4 : 40)}px;
  cursor: ${(props) => (props.$bottom ? 'ns-resize	' : 'ew-resize')};
  border-radius: 2px;
  background: #dfdfea;
  transition: 300ms ease-out;
  &:hover {
    background: #99a9cc;
  }
`;
