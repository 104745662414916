import React from 'react';
import { graphql } from 'gatsby';

import { ThemeProvider } from '@/themes/theme';
import { GlobalStyle } from '@/themes/globalStyle';
import { removeSpaces } from '@/utils/functions';
import { Meta } from '@/components/general/Meta';
import { Links } from '@/utils/constants';
import { PWAPreview } from '@/components/PWAPreview';

const TemplatePreviewPage = ({ pageContext, data }) => (
  <ThemeProvider>
    <Meta
      title={`${pageContext.templateTitle} Preview | CodePlatform`}
      description={data.contentfulTemplate.description.description}
      image={data.contentfulTemplate.mainIcon.url}
      url={`${Links.TEMPLATES}/${removeSpaces(
        pageContext.templateTitle,
      )}/preview`}
    />
    <GlobalStyle />
    <PWAPreview data={data.contentfulTemplate} />
  </ThemeProvider>
);

export default TemplatePreviewPage;

export const query = graphql`
  query TemplateQueryForPreviewPage($templateId: String) {
    contentfulTemplate(id: { eq: $templateId }) {
      title
      mainIcon {
        url
      }
      categories
      technologies
      description {
        description
      }
      studioTemplateId
      includesList {
        icon {
          url
        }
        title
        description
      }
      previewUrl
    }
  }
`;
